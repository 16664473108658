let BASE_URL, OSS_URL, GC_PROD_URL, GC_PAY_URL, GC_ACCESS_URL, ACCOUNT_TYPE = 1000;
// @ts-ignore
console.log('ENV', ENV)
let GS_TC_URL = 'http://gcts-wechat.beta.shrise.cn/';
let QT_WEB_HTTP_URL = 'https://q-api.shrise.cn';//行情web
let WS_URL = 'wss://im-gateway-beta1.shrise.cn/room/'


switch(ENV) {
  case 'beta0':
    BASE_URL = 'https://api-web-beta0.shrise.cn';
    OSS_URL = 'https://tj-file.oss.shrise.cn/';
    GC_ACCESS_URL = "https://gc-access-beta0.shrise.cn/"
    GC_PROD_URL = 'https://gc-prod-beta0.shrise.cn/';
    GC_PAY_URL = 'https://gc-pay-beta0.shrise.cn/';
    break;
  case 'beta1':
    BASE_URL = 'https://api-web-beta1.shrise.cn';
    OSS_URL = 'https://tj-file.oss.shrise.cn/';
    GC_ACCESS_URL = "https://gc-access-beta1.shrise.cn/"
    GC_PROD_URL = 'https://gc-prod-beta1.shrise.cn/';
    GC_PAY_URL = 'https://gc-pay-beta1.shrise.cn/';
    WS_URL = 'wss://im-gateway-beta1.shrise.cn/room/'
    ACCOUNT_TYPE = 1099;
    break;
  case 'beta2':
    BASE_URL = 'https://api-web-beta2.shrise.cn';
    OSS_URL = 'https://tj-file.oss.shrise.cn/';
    GC_ACCESS_URL = "https://gc-access-beta2.shrise.cn/"
    GC_PROD_URL = 'https://gc-prod-beta2.shrise.cn/';
    GC_PAY_URL = 'https://gc-pay-beta2.shrise.cn/';
    ACCOUNT_TYPE = 1000;
    break;
  case 'beta3':
    BASE_URL = 'https://api-web-beta3.shrise.cn';
    OSS_URL = 'https://tj-file.oss.shrise.cn/';
    GC_ACCESS_URL = "https://gc-access-beta3.shrise.cn/"
    GC_PROD_URL = 'https://gc-prod-beta3.shrise.cn/';
    GC_PAY_URL = 'https://gc-pay-beta3.shrise.cn/';
    ACCOUNT_TYPE = 1001;
    break;
  case 'beta4':
    BASE_URL = 'https://api-web-beta4.shrise.cn';
    OSS_URL = 'https://tj-file.oss.shrise.cn/';
    GC_ACCESS_URL = "https://gc-access-beta4.shrise.cn/"
    GC_PROD_URL = 'https://gc-prod-beta4.shrise.cn/';
    GC_PAY_URL = 'https://gc-pay-beta4.shrise.cn/';
    ACCOUNT_TYPE = 1002;
    break;
  case 'beta5':
    BASE_URL = 'https://api-web-beta5.shrise.cn';
    OSS_URL = 'https://tj-file.oss.shrise.cn/';
    GC_ACCESS_URL = "https://gc-access-beta5.shrise.cn/"
    GC_PROD_URL = 'https://gc-prod-beta5.shrise.cn/';
    GC_PAY_URL = 'https://gc-pay-beta5.shrise.cn/';
    ACCOUNT_TYPE = 1002;
    break;
  default:
    BASE_URL = 'https://api-web.cf69.cn';
    OSS_URL = 'https://tj-file.oss.shrise.cn/';
    GS_TC_URL = 'https://wxts.cf69.cn/'
    GC_ACCESS_URL = "https://gc-access.cf69.cn/"
    GC_PROD_URL = 'https://gc-prod.cf69.cn/';
    GC_PAY_URL = 'https://gc-pay.cf69.cn/';
    ACCOUNT_TYPE = 1099;
}


const PRODUCT_TYPE = 177;
const COMPANY_TYPE = 45;
const WHITE_PAGE_LIST = ["/authorization"]

export default {
  ACCOUNT_TYPE,
  PRODUCT_TYPE,
  GC_PAY_URL,
  GS_TC_URL,
  GC_PROD_URL,
  BASE_URL,
  OSS_URL,
  WS_URL,
  QT_WEB_HTTP_URL,
  GC_ACCESS_URL,
  WHITE_PAGE_LIST,
  COMPANY_TYPE
}
